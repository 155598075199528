import React, {FC} from 'react';
import './EmptySpaceForFixedButtons.css';

export interface EmptySpaceForFixedButtonsProps {
  numberOfButtons: number;
}

export const EmptySpaceForFixedButtons: FC<EmptySpaceForFixedButtonsProps> = ({numberOfButtons}) => {
  return (
    <div className={`emptySpaceForFixedButtons ${numberOfButtons == 2 ? 'two-buttons-height' : 'three-buttons-height'} `}></div>
  );
}
