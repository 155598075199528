import React, { ReactElement } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  element: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { user } = useAuthenticator();

  if (!user) {
    // @ts-ignore
    return <Navigate to="/" replace={true} />;
  }

  return element;
};

export default ProtectedRoute;
