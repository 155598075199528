import React, {FC} from 'react';
import './TextInput.css';

export interface TextInputProps {
  label: string;
  inputName: string;
  inputValue: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  enableValidation?: boolean;
  showError?: boolean;
  errorMessage?: string;
}

export const TextInput: FC<TextInputProps> = ({label, inputName, onChangeHandler, inputValue, showError = false, errorMessage}) => {

  return (
    <div className="textInputContainer">
      <label htmlFor={ inputName }>{ label }</label>
      <input type="text" id={ inputName } className={ `textInputBox ${inputName}` } name={inputName} onChange={ onChangeHandler } value={ inputValue }/>
      {
        (showError) && <span className="errorMessage">{errorMessage}</span>
      }
    </div>
  );
}
