import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk';

const root = ReactDOM.createRoot(
  document.getElementById('react-root') as HTMLElement,
);

(async () => {
  const clientSideID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;
  if (!clientSideID) {
    throw new Error('LaunchDarkly Client-Side ID is not provided. Please set REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID in the environment.');
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientSideID,
    context: {
      "kind": "anonymousUser",
      "key": "anonymous",
      anonymous: true,
    }
  });

  root.render(
    <React.StrictMode>
      <LDProvider>
        <App/>
      </LDProvider>
    </React.StrictMode>,
  );
})();