import { createContext, useContext } from 'react';

export type HomePageState = {
  automationInProgress: boolean;
  brandSelected: string;
};

interface HomePageContextProps {
  homePageState: HomePageState;
  // eslint-disable-next-line no-unused-vars
  setHomePageState: (homePageState: HomePageState) => void;
}

export const homePageInitialState: HomePageState = {
  automationInProgress: false,
  brandSelected: '',
};

export const HomePageContext = createContext<HomePageContextProps>({
  homePageState: homePageInitialState,
  setHomePageState: () => {},
});

export const useHomePageContext = (): HomePageContextProps => {
  const context = useContext(HomePageContext);
  if (!context) {
    throw new Error('useHomePageContext must be used within a HomePageProvider');
  }
  return context;
};

type ChatReasonType = {
  reason: string;
  // eslint-disable-next-line no-unused-vars
  setReason: (reason: string) => void;
}

export const ChatReasonContext = createContext<ChatReasonType>({
  reason: '',
  setReason: () => {},
});
