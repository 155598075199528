import { createContext, useContext } from 'react';

export interface DecommissionInputs {
  onsiteRadioAnswer: string;
  meterTypeRadioAnswer: string;
  mpxn: string;
  msn: string;
};

interface DecommissionInputsContextProps {
  decommissionInputs: DecommissionInputs;
  setDecommissionInputs: (decommissionInputs: DecommissionInputs) => void;
}

export const decommissionInputsInitialState: DecommissionInputs = {
  onsiteRadioAnswer: '',
  meterTypeRadioAnswer: '',
  mpxn: '',
  msn: '',
};

export const DecommissionInputsContext = createContext<DecommissionInputsContextProps | null>({
  decommissionInputs: decommissionInputsInitialState,
  setDecommissionInputs: () => {},
});

export const useDecommissionInputsContext = (): DecommissionInputsContextProps => {
  const context = useContext(DecommissionInputsContext);
  if (!context) {
    throw new Error('useDecommissionInputsContext must be used within a DecommissionInputsProvider');
  }
  return context;
};
