import React, {FC} from "react";
import './InformationPanel.css';

export interface InformationPanelProps {
  title: string | null;
  textArray: TextParagraph[];
  background: boolean;
  dataTestId?: string;
}

export interface TextParagraph {
  text: string;
}

export const InformationPanel: FC<InformationPanelProps> = ({title = null, textArray, background, dataTestId }) => {
  return (
    <div className={`informationPanelComponent ${background ? ' informationPanelWithBackground' : ''}`} data-testid={dataTestId ?? ''}>
      { title != null && <h1 className={'informationHeading'}>{title}</h1>}
      {textArray.map((textParagraph: TextParagraph, index: number) => {
        return (
        <p key={`paragraph${index}`}>{textParagraph.text}</p>
        )
      })}
    </div>
  );
}
