import React, { useEffect, useState } from 'react';

export default function TextInput({
// @ts-ignore
  label, inputName, error, kind, changeFunc, value, enableValidation = true,
}) {
  const [validation, setValidation] = useState(true);
  useEffect(() => setValidation(enableValidation));

  function handleChange(e: any) {
    setValidation(true);
    changeFunc(e);
  }

  return (
    <div className="commsCheckInputContainer">
      <label htmlFor={ inputName }>{ label }</label>
      <input type="text" id={ inputName } className={ `commsCheckInputBox ${inputName}` } name="commsCheck" placeholder={ `Enter your ${kind} here` } onChange={ handleChange } value={ value }/>
      {
        (validation && !!error) && <span className="errorMessage">{error}</span>
      }
    </div>
  );
}
