import React, {FC} from 'react';
import './ButtonContainer.css';

export interface ButtonContainerProps extends React.PropsWithChildren {
  buttonContainerId?: string;
  dataTestId?: string;
}

export const ButtonContainer: FC<ButtonContainerProps> = ({children, dataTestId}) => {
  return (
      <div className="btn-container" data-testid={dataTestId ?? ''}>
            {children}
      </div>
  );
}
