import React, { useContext } from 'react';
import { signOut } from 'aws-amplify/auth';
import { startChat } from './chat-jq';
import { showChatReasons } from './chat-reasons-v2';
import { ChatReasonContext, useHomePageContext } from '../../context/HomePageContext';
import { Button, ButtonTheme } from "../common/button/Button";
import { ButtonContainer } from "../common/buttonContainer/ButtonContainer";
import { getAuthTokenOrLogOut } from "../util";
import { useFlags } from "launchdarkly-react-client-sdk";

type ChatProps = { className?: string; };

export default function Chat({className}: ChatProps) {
  const { homePageState, setHomePageState } = useHomePageContext();
  const { reason, setReason } = useContext(ChatReasonContext);
  const { enableDecommissioning } = useFlags();

  function handleBrandSelection(e: any) {
    setReason('');
    setHomePageState({
      ...homePageState,
      brandSelected: e.target.value,
    });
    showChatReasons(e.target.value, (chatReasonSelected: string) => {
      setReason(chatReasonSelected);
    });
  }

  async function startChatHandler() {
    const {userEmail, token} = await getAuthTokenOrLogOut();
    if (token) {
      startChat('', userEmail, token, '');
    }
  }

  async function logOutHandler() {
    await signOut();
  }

  return (
    <form name="contactDetail" id="contactDetails" className={`login100-form ${className}`} action="/index.html" onSubmit={ (e) => e.preventDefault() } data-testid="chat-form">
      <div className="brand-options-container">
        <p className="chat-reasons-p" data-testid="select-brand-question">What brand are you contacting about?</p>
        <div className="brand-container">
          <label htmlFor="OVO" className="brand-radio-btn">OVO</label>
          <input type="radio" className="brand-radio-btn" id="OVO" name="brand" value="OVO" onChange={handleBrandSelection}/>
        </div>
        <hr className="solid"/>
        <div className="brand-container">
          <label htmlFor="BOOST" className="brand-radio-btn">BOOST</label>
          <input type="radio" className="brand-radio-btn" id="BOOST" name="brand" value="BOOST" onChange={handleBrandSelection}/>
        </div>
      </div>
      <div className="chat-reasons-container" style={{ display: 'none' }}>
        <p className="chat-reasons-p">What issue are you having?</p>
        <div id="chat-reasons-dropdown"></div>
      </div>
      {(reason !== 'Engineer comms checker' && (!enableDecommissioning || (reason !== 'Decommission a Meter' && enableDecommissioning))) &&
        <ButtonContainer>
          <Button buttonId={'startChatFirstPage'} buttonText={'Start Chat'} buttonTheme={ reason != '' ? ButtonTheme.PRIMARY : ButtonTheme.SECONDARY} isButtonEnabled={!(!homePageState.brandSelected && !reason)}
                  dataTestId={'chat-start-chat-btn'}
                  onClickHandler={startChatHandler} />
          <Button buttonId={'logoutFirstPage'} buttonText={'Log Out'} buttonTheme={ButtonTheme.SECONDARY} dataTestId={'log-out-btn'}
                  onClickHandler={logOutHandler}/>
        </ButtonContainer>}
    </form>
  );
}
