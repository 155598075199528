import { createContext, useContext } from 'react';

export type Inputs = {
  mpxn: string;
  msn: string;
  mpxnError: string;
  msnError: string;
};

interface InputsContextProps {
  inputs: Inputs;
  // eslint-disable-next-line no-unused-vars
  setInputs: (inputs: Inputs) => void;
}

export const inputsInitialState: Inputs = {
  mpxn: '',
  msn: '',
  mpxnError: '',
  msnError: '',
};

export const InputsContext = createContext<InputsContextProps>({
  inputs: inputsInitialState,
  setInputs: () => {},
});

export const useInputsContext = (): InputsContextProps => {
  const context = useContext(InputsContext);
  if (!context) {
    throw new Error('useInputsContext must be used within a InputsProvider');
  }
  return context;
};
