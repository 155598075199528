import React from 'react';
import CommsCheck from '../comms-check/CommsCheck';
import Chat from '../chat/Chat';
import DecommissionAMeter from '../../views/automation/decommissionAMeter/DecommissionAMeter';
import {
  ChatReasonContext,
  HomePageContext,
  homePageInitialState,
  HomePageState,
} from '../../context/HomePageContext';
import { Inputs, InputsContext } from '../../context/InputsContext';
import { getParamsFromURL } from '../chat/chat-jq';
import {
  DecommissionInputs,
  DecommissionInputsContext,
  decommissionInputsInitialState
} from "../../context/DeommissionContext";
import {useFlags} from "launchdarkly-react-client-sdk";

export default function HomePage() {
  const [homePageState, setHomePageState] = React.useState<HomePageState>(homePageInitialState);
  const [reason, setReason] = React.useState<string>('');
  const params = getParamsFromURL();
  const [inputs, setInputs] = React.useState<Inputs>({
    mpxn: params.mpxn ?? '',
    msn: params.msn ?? '',
    mpxnError: '',
    msnError: '',
  });
  const [decommissionInputs, setDecommissionInputs] = React.useState<DecommissionInputs>(decommissionInputsInitialState);

  const { enableDecommissioning } = useFlags();
  return (
    <HomePageContext.Provider value={{ homePageState, setHomePageState }}>
      <ChatReasonContext.Provider value={{ reason, setReason }}>
        <Chat className={homePageState.automationInProgress ? 'displayNone' : undefined} />
        <span id="accessDeniedError" style={{ display: 'none' }} className="errorMessage">Oops, try again in 10 seconds</span>
        <InputsContext.Provider value={{ inputs, setInputs }}>
          { reason === 'Engineer comms checker' && <CommsCheck /> }
        </InputsContext.Provider>
        <DecommissionInputsContext.Provider value={{ decommissionInputs, setDecommissionInputs }}>
          { enableDecommissioning && reason === 'Decommission a Meter' ? <DecommissionAMeter/> : null }
        </DecommissionInputsContext.Provider>
      </ChatReasonContext.Provider>
    </HomePageContext.Provider>
  );
}
