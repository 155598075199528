/* eslint-disable */
export const ovoSelectOptions = [
  {
    text: 'Alt-HAN query',
    queue: '2',
  },
  {
    text: 'Checking progress',
    queue: '1',
  },
  {
    text: 'Checking site details',
    queue: '1',
  },
  {
    text: 'Customer is off supply',
    queue: '2',
  },
  {
    text: 'Decommission a CH/IHD',
    queue: '2',
  },
  {
    text: 'Decommission a Meter',
    queue: '2',
  },
  {
    text: 'Elec error',
    queue: '2',
  },
  {
    text: 'Engineer comms checker',
    queue: '1',
  },
  {
    text: 'Fault with IHD',
    queue: '2',
  },
  {
    text: 'Gas error',
    queue: '2',
  },
  {
    text: 'Job query',
    queue: '2',
  },
  {
    text: 'Need commissioning sent',
    queue: '1',
  },
  {
    text: 'Suspected no WAN',
    queue: '2',
  },
];

export const boostSelectOptions = [
  {
    text: 'Alt-HAN query',
    queue: '2',
  },
  {
    text: 'Checking progress',
    queue: '1',
  },
  {
    text: 'Checking site details',
    queue: '1',
  },
  {
    text: 'Customer is off supply',
    queue: '2',
  },
  {
    text: 'Decommission a CH/IHD',
    queue: '2',
  },
  {
    text: 'Decommission a Meter',
    queue: '2',
  },
  {
    text: 'Elec error',
    queue: '2',
  },
  {
    text: 'Engineer comms checker',
    queue: '1',
  },
  {
    text: 'Fault with IHD',
    queue: '2',
  },
  {
    text: 'Gas error',
    queue: '2',
  },
  {
    text: 'Job query',
    queue: '2',
  },
  {
    text: 'Need commissioning sent',
    queue: '1',
  },
  {
    text: 'Suspected no WAN',
    queue: '2',
  },
];

const validateBrand = () => {
  if ($('input[name=brand]').is(':checked')) {
    return true;
  }
  return false;
};
const validateChatReason = () => {
  if ($('.dd-selected').text() !== 'Select issue') {
    return true;
  }
  return false;
};

export function validateStartChatInfo() {
  if (!validateBrand()) {
    return false;
  }
  if (!validateChatReason()) {
    return false;
  }

  return true;
}

export const showChatReasons = (brandSelected, auxSelectReason) => {
  $('#chat-reasons-dropdown').ddslick('destroy');
  $('.chat-reasons-container').show();
  if (brandSelected === 'OVO') {
    renderSelectDropdown(ovoSelectOptions, auxSelectReason);
  } else {
    renderSelectDropdown(boostSelectOptions, auxSelectReason);
  }
};

export function renderSelectDropdown(chatReasonOptions, auxSelectReason) {
  if ($('input[name=brand]').is(':checked')) {
    $('#chat-reasons-dropdown').ddslick({
      data: chatReasonOptions,
      selectText: 'Select issue',
      onSelected() {
        const selectChatReason = $('.dd-selected-text').text();
        if (selectChatReason === 'Engineer comms checker') {
          validateStartChatInfo();
        } else {
          validateStartChatInfo();
        }
        auxSelectReason(selectChatReason);
      },
    });
    $('<i class="fa fa-search" id="searchIcon"></i>').appendTo($('#chat-reasons-dropdown'));
  } else {
    $('#chat-reasons-dropdown').ddslick();
  }
}
