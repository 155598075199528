import { CommsCheckResult } from '../types/comms-check';

const commsCheckInitialData: CommsCheckResult = {
  xrinv: {
    data: [],
    state: null,
  },
  xritm: {
    data: [],
    state: null,
  },
  xrdlg: {
    data: [],
    state: null,
  },
};

export default commsCheckInitialData;
